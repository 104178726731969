import React from "react";
import { Spinner } from 'react-bootstrap';

interface CompProp{
    className?: string;
}

export const SpinnerCenter = ({className}: CompProp) => {
    return <div className="d-flex justify-content-center align-items-center h-100 w-100">
        <Spinner animation="border" className={className}/>
    </div>
}