import { useEffect, useState } from "react";
import { PadTo2 } from "../helper/constant";

export const Timer = ({
  ms,
  panicTime = 60000,
}: {
  ms: number;
  panicTime?: number;
}) => {
  const [timeLeft, setTimeLeft] = useState(ms);
  
  const interval = 1000;

  let intRef: any;

  useEffect(() => {
    intRef = setTimeout(() => {
      const nextTimeLeft = timeLeft - interval;
      if (nextTimeLeft < 0) {
        setTimeLeft(0);
        clearTimeout(intRef);
        localStorage.removeItem("checkoutID");
        return;
      }

      setTimeLeft(nextTimeLeft);
    }, interval);

    return () => {
      clearTimeout(intRef);
    };
  });

  const sec = Math.floor((timeLeft / 1000) % 60);
  const min = Math.floor(timeLeft / 60000);

  return (
    <span>
      {PadTo2(min)}:{PadTo2(sec)}
    </span>
  );
};
