import axios from "axios";

export const Api_URL = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: Api_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const removeMerchantKeyPrefix = (plan: any): PlanWithMerchantKey => {
  const newPlan: any = { ...plan };

  for (const key in plan) {
    if (key.startsWith('MerchantKey.')) {
      const newKey = key.replace('MerchantKey.', '');
      newPlan[newKey] = plan[key];
      delete newPlan[key];
    }
  }

  return newPlan as PlanWithMerchantKey;
};

export const FullForm = {
  'D' : "Days",
  'W' : "Week",
  'M' : "Month",
  'Y' : "Year"
}

export const GetCurrencySymbol = (currency: string): string => {
  switch (currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    default:
      return "";
  }
};

export const PadTo2 = (x: string | number) => {
  x = `${x}`;
  if (x.length <= 1) return `0${x}`;
  return x;
};

export const subscriptionStatus = {
  "created": "A new subscription is created",
  "pending": "The subscription is now awaiting a payment & has a pending mandate attached to the subscription",
  "active": "The subscription has been paid for the current period & is considered as active",
  "halted": "That the subscription, which was initially in pending state, has its mandate expired causing to forceful termination of the subscription",
  "completed": "The subscription has completed all its billing cycles and is now terminated",
  "paused": "The subscription has been paused. Only subscriptions in active state can be paused",
  "cancelled": "The subscription has been cancelled and can no longer be continued"
}

export const subscriptionStatusColor = {
  "created": 'warning',
  "pending": 'warning',
  "active": 'success',
   "halted": 'danger',
   "completed": 'success',
   "paused": 'info',
   "cancelled": 'light',
}

export function translateMessage(id: string, lang: any): any {
  const text = GetTextJson(lang)[id] ? GetTextJson(lang)[id] : id;
  return text;
}

const GetTextJson = (lang: any) => {
  return require(`../assets/texts/${lang}.json`);
};

export const convertToSnakeCase = (str: string): string =>
  str.toLowerCase().replace(/ /g, "_");