import React from 'react';
import styles from './main.module.scss';
import { useSelector } from 'react-redux';
import { translateMessage } from '../../helper/constant';
import { useLocalStorage } from '../../hook/useLocalStorage';

const ContainerHeader = () => {
  const [lang] = useLocalStorage('lang', 'en');
	const { subscriptionPlan } = useSelector((state: RootState) => state.subscriptionReducer)

  return (
    <div className={`${styles.containerHeader}`}>
      <div className={`${styles.textSection}`}>
        <h3>{translateMessage("Subscribe to", lang)}</h3>
        <h1 className='d-none d-md-block'>{subscriptionPlan?.labelName}</h1>
      </div>
      <div className={`${styles.logoSection}`}>
        <img src={subscriptionPlan?.logo} alt={translateMessage('Store logo', lang)} className={styles.storeLogo}/>
      </div>
      <div className={`d-block d-md-none ${styles.textSection}`}>
        <h1>{subscriptionPlan?.labelName}</h1>
      </div>
    </div>
  )
}

export default ContainerHeader;
