import Main from "./Main";
import TopBar from "../TopBar";
import { useEffect } from "react";
import { AppDispatch } from "../..";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styles from "./container.module.scss"
import * as actions from "../../store/actions";
import { useHistory, useParams } from "react-router";
import { SpinnerCenter } from "../common/SpinnerCenter";

const Container = (props: any) => {
	const { id } = useParams<{ id: string }>();
	const dispatch: AppDispatch = useDispatch();
	const history = useHistory();

	const { loading, subscriptionUser } = useSelector((state: RootState) => state.subscriptionReducer)

	useEffect(() => {
		if (!id) {
			history.push('/');
		} else {
			dispatch(actions.fetchSubscriptionUser(id, true));
			setInterval(() => {
				dispatch(actions.fetchSubscriptionUser(id, false));
			}, 10000);
		}
	}, [id]);

	return (
		<div className={`${styles.mainContainer}`}>
			{(loading) ? <SpinnerCenter /> : <>
				<TopBar/>
				<Main />
			</>}
		</div>
	);
}

export default Container;
