import { translateMessage } from '../../helper/constant';
import { useLocalStorage } from '../../hook/useLocalStorage';
import styles from './main.module.scss';

const MainSection = () => {
  const [lang] = useLocalStorage('lang', 'en');

  return (
    <div className={`${styles.mainSection}`}>
      <hr className='d-md-none'/>
      <div className={`${styles.lftSection}`}>
        <div className={`${styles.lftSection__top}`}>
          <p>{translateMessage("Setup recurring payments for your customers.", lang)}<br/><br/>
{translateMessage("Get notified of payments, missed payments and easily manage halting and cancellation of subscriptions via our webhooks.", lang)}</p>
        </div>
        <div className={`${styles.lftSection__bottom}`}>
        </div>
      </div>
      <hr className='d-md-none'/>
      <div className={`d-none ${styles.rtSection}`}>
      </div>
    </div>
  )
}

export default MainSection