import React from 'react';
import ContainerHeader from './Header';
import ContainerFooter from '../Main/Footer';
import styles from './main.module.scss';
import MainSection from './MainSection';
import { useSelector } from 'react-redux';
import { translateMessage } from '../../helper/constant';
import { useLocalStorage } from '../../hook/useLocalStorage';

const Main: React.FC = () => {
  const [lang] = useLocalStorage('lang', 'en');
  const { subscriptionPlan } = useSelector((state: RootState) => state.subscriptionReducer)

  return (
    <div className={styles.mainContainer}>
      {!subscriptionPlan ? <h1>{translateMessage("No Plan found", lang)}</h1> : <>
        <ContainerHeader />
        <MainSection /></>}
      <ContainerFooter />
    </div>
  );
};

export default Main;
