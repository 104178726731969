import { LOADING, SET_SUBSCRIPTION_USER, SET_SUBSCRIPTION_PLAN, ERROR, SUBSCRIBE_USER, SUCCESS, API_LOADING } from './actionTypes';
import { Dispatch } from 'redux';
import axios from 'axios';
import { axiosInstance, removeMerchantKeyPrefix } from '../../helper/constant';

// Action types
interface LoadingAction {
  type: typeof LOADING;
  loading: boolean;
}

interface ApiLoadingAction {
  type: typeof API_LOADING;
  loading: boolean;
}

interface SetSubscriptionUserAction {
  type: typeof SET_SUBSCRIPTION_USER;
  payload: SubscriptionUser; 
}

interface SetSubscriptionPlanAction {
  type: typeof SET_SUBSCRIPTION_PLAN;
  payload: PlanWithMerchantKey; 
}

interface ErrorAction {
  type: typeof ERROR;
  error: string | null;
}
interface SuccessAction {
  type: typeof SUCCESS;
  message: string | null;
}

interface SubscribeUserAction {
  type: typeof SUBSCRIBE_USER;
  payload: {
    message: string;
    data: {
      link: string;
      subscriptionUserId: string;
    }
  };
}

export type SubscriptionAction =
  | LoadingAction
  | ApiLoadingAction
  | SetSubscriptionUserAction
  | SetSubscriptionPlanAction
  | ErrorAction
  | SuccessAction
  | SubscribeUserAction;

export const fetchSubscriptionUser = (subscriptionUserId: string, load: boolean) => {
  console.log("🚀 ~ fetchSubscriptionUser ~ load:", load)
  return async (dispatch: Dispatch<SubscriptionAction>) => {
    load && dispatch({ type: LOADING, loading: true });

    try {
      const response = await axiosInstance.post('/subscription/get-subscription-user', {
        subscriptionUserId
      });
      dispatch({ type: SET_SUBSCRIPTION_USER, payload: {...response.data.data, plan: removeMerchantKeyPrefix(response.data.data.plan)} });
    } catch (error: any) {
      dispatch({ type: ERROR, error: error.message });
    } finally {
      dispatch({ type: LOADING, loading: false });
    }
  };
};

export const fetchSubscriptionPlan = (planId: string, load: boolean) => {
  return async (dispatch: Dispatch<SubscriptionAction>) => {
    load && dispatch({ type: LOADING, loading: true });

    try {
      const response = await axiosInstance.post('/subscription/get-subscription-plan', {
        planId
      });
      dispatch({ type: SET_SUBSCRIPTION_PLAN, payload: removeMerchantKeyPrefix({ ...response.data.data }) });
    } catch (error: any) {
      dispatch({ type: ERROR, error: error.message });
    } finally {
      dispatch({ type: LOADING, loading: false });
    }
  };
};

export const subscribeUser = (id: string, email: string, startsAt?: string, cb?: Function) => {
  return async (dispatch: Dispatch<SubscriptionAction>) => {
    dispatch({ type: API_LOADING, loading: true });
    const data = {
      planId: id,
      customerId: email,
      customerEmail: email,
      ...(startsAt && { startsAt })
    };

    try {
      const response: any = await axiosInstance.post('/subscription/create-user-subscription', {
        ...data
      });
      if(response && (response.status === 200 || response.status === 201)){
        dispatch({ type: SUCCESS, message: response.data.message });
        cb && cb(response.data.data.subscriptionUserId);
      }
    } catch (error: any) {
      dispatch({ type: ERROR, error: error.message });
    } finally {
      dispatch({ type: API_LOADING, loading: false });
    }
  };
};

export const Error = (string: string | null) => {
  return async (dispatch: Dispatch<SubscriptionAction>) => {
    dispatch({ type: ERROR, error: string });
  }
};

export const Message = (string: string | null) => {
  return async (dispatch: Dispatch<SubscriptionAction>) => {
    dispatch({ type: SUCCESS, message: string });
  }
};