import { useSelector } from "react-redux";
import { convertToSnakeCase, GetCurrencySymbol } from "../../helper/constant";


type Props = {
  input: DynInput;
  onChange: (fieldKey: string, value: any, inputType?: string) => void;
};

const DynamicInput = ({ input, onChange }: Props) => {
  const { subscriptionPlan } = useSelector((state: RootState) => state.subscriptionReducer);

  const qtyOnChange = (input: DynInput, operation: "inc" | "dec") => {
    const { label, maxQty, minQty, rate, value, quantity } = input;

    if (!quantity && operation === "inc") {
      onChange(label, Number(minQty) + 1, "qty-selector");
    } else {
      if (operation === "inc" && quantity) {
        if (quantity >= Number(maxQty)) {
          return null;
        } else {
          onChange(label, quantity + 1, "qty-selector");
        }
      }
      if (operation === "dec" && quantity) {
        if (quantity <= Number(minQty)) {
          return null;
        } else {
          onChange(label, quantity - 1, "qty-selector");
        }
      }
    }
  };

  const renderInput = (input: DynInput) => {
    switch (input.type) {
      case "text":
        return (
          <>
            <input
              className="form-input"
              type={input.type}
              id={convertToSnakeCase(input.label)}
              name={convertToSnakeCase(input.label)}
              required={input.mandatory}
              defaultValue={input.defaultValue}
              value={input.value}
              disabled={input.disabled}
              onChange={(e) => onChange(input.label, e.target.value)}
            />
            <span className="description">{input.description}</span>
          </>
        );
      case "textarea":
        return (
          <>
            <textarea
              className="form-textarea"
              id={convertToSnakeCase(input.label)}
              name={convertToSnakeCase(input.label)}
              required={input.mandatory}
              defaultValue={input.defaultValue}
              value={input.value}
              disabled={input.disabled}
              onChange={(e) => onChange(input.label, e.target.value)}
              rows={5}
            />
            <span className="description">{input.description}</span>
          </>
        );
      case "fixed-amount":
        return (
          <>
            <div className="w-100" style={{ position: "relative" }}>
              <div className="dollar-sign">
                {GetCurrencySymbol(subscriptionPlan?.planCurrency || "USD")}
              </div>
              <input
                className="form-input"
                type={"number"}
                id={convertToSnakeCase(input.label)}
                name={convertToSnakeCase(input.label)}
                required={input.mandatory}
                value={input.value}
                disabled={true}
              />
            </div>
            <span className="description">{input.description}</span>
          </>
        );
      case "number":
      case "custom-amount":
        return (
          <>
            <div className="w-100" style={{ position: "relative" }}>
              <div className="dollar-sign">
                {GetCurrencySymbol(subscriptionPlan?.planCurrency || "USD")}
              </div>
              <input
                className="form-input"
                type={"number"}
                id={convertToSnakeCase(input.label)}
                name={convertToSnakeCase(input.label)}
                required={input.mandatory}
                defaultValue={input.defaultValue}
                value={input.value || input.defaultValue}
                disabled={input.disabled}
                onChange={(e) => onChange(input.label, e.target.value)}
              />
            </div>
            <span className="description">{input.description}</span>
          </>
        );
      case "dropdown":
        return (
          <>
            <select
              className="form-select"
              id={convertToSnakeCase(input.label)}
              name={convertToSnakeCase(input.label)}
              required={input.mandatory}
              defaultChecked={
                input.defaultValue
                  ? input.defaultValue
                  : input.value
                  ? input.value
                  : undefined
              }
              onChange={(e) => onChange(input.label, e.target.value)}
            >
              {input.hasOwnProperty("options") &&
                input.options &&
                input.options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
            <span className="description">{input.description}</span>
          </>
        );
      case "radio":
        return (
          <>
            <div className="d-flex justify-content-start align-items-center flex-wrap px-[20px]">
              {input.hasOwnProperty("options") &&
                input.options &&
                input.options.map((ele, idx) => (
                  <div
                    className="form-check form-check-inline form-radio"
                    key={idx}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`inlineRadioOptions-${convertToSnakeCase(
                        input.label
                      )}`}
                      id={`inlineRadio1-${idx}`}
                      value={ele.value}
                      checked={input.value === ele.value ? true : false}
                      onChange={(e) => {
                        onChange(input.label, e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label d-flex align-items-center"
                      htmlFor={`inlineRadio1-${idx}`}
                    >
                      {ele.label}
                    </label>
                  </div>
                ))}
            </div>
            <span className="description">{input.description}</span>
          </>
        );
      case "qty-selector":
        return (
          <>
            <div
              className={`qty-selector-img-wrapper ${
                input.image ? "show-image" : ""
              }`}
            >
              {input.image && (
                <div className="qty-selector-img-box">
                  <img src={input.image} alt="multiplier-img" />
                </div>
              )}
              <div className="qty-selector-wrapper">
                <span>
                  {parseFloat(
                    (
                      Number(input.rate) *
                      (Number(input.quantity) || Number(input.minQty))
                    ).toFixed(2)
                  )}
                </span>
                <div className="qty-selector-input-block">
                  <button onClick={() => qtyOnChange(input, "dec")}>-</button>
                  <input
                    type={input.type}
                    id={convertToSnakeCase(input.label)}
                    name={convertToSnakeCase(input.label)}
                    required={input.mandatory}
                    value={input.quantity || input.minQty}
                    disabled={true}
                  />
                  <button onClick={() => qtyOnChange(input, "inc")}>+</button>
                </div>
              </div>
            </div>
            <span className="description">{input.description}</span>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="formST-dynamicInput">
      <label htmlFor={convertToSnakeCase(input.label)}>
        {input.label} {input.mandatory && <span>*</span>}
      </label>
      {renderInput(input)}
    </div>
  );
};

export default DynamicInput;
