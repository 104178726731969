import styles from './main.module.scss';
import { ReactComponent as InfoSVG } from '../../assets/svg/info.svg';
import { Alert, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SubButton from './SubButton';
import { useSelector } from 'react-redux';
import { FullForm, GetCurrencySymbol, subscriptionStatus, subscriptionStatusColor, translateMessage } from '../../helper/constant';
import { Timer } from '../Timer';
import React from 'react';
import ReadableDate from '../../helper/ReadableDate';
import { useLocalStorage } from '../../hook/useLocalStorage';

interface ToolTip {
  status: "created" | "pending" | "active" | "halted" | "completed" | "paused" | "cancelled"
}
const TooltipComponent: React.FC<ToolTip> = ({ status }) => {
  const [lang] = useLocalStorage('lang', 'en');

  const renderTooltip = (props: any) => (
    <Tooltip id="info-tooltip" {...props}>
      {translateMessage(subscriptionStatus[status], lang)}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Badge bg={subscriptionStatusColor[status]}>{translateMessage(status, lang)}</Badge>
    </OverlayTrigger>
  );
};

const ItemsList = () => {
  const { subscriptionUser } = useSelector((state: RootState) => state.subscriptionReducer);
  const { plan } = subscriptionUser!;

  return (
    <div className={`${styles.itemsList}`}>
      <div>
        <b>{plan?.planName}</b>
        <p>{plan?.planDescription}</p>
      </div>
      <div>
        <b className='text-nowrap'>{`${GetCurrencySymbol(plan?.planCurrency!)} ${plan?.planCost}`}</b>
      </div>
    </div>
  )
};

const timeInMs = (expiry: string): number => {
  const date = expiry ? new Date(expiry) : new Date();
  const date2 = new Date();
  return date.getTime() - date2.getTime();
};

const MainSection = () => {
  const [lang] = useLocalStorage('lang', 'en');
  const { subscriptionUser } = useSelector((state: RootState) => state.subscriptionReducer);
  const { plan, subscription, activeMandate } = subscriptionUser!;

  return (
    <div className={`${styles.mainSection}`}>
      <hr className='d-md-none' />
      <div className={`${styles.lftSection}`}>
        <div className={`${styles.lftSection__top}`}>
          <p><span>{translateMessage("Subscription Plan", lang)}</span>
            <span>{<TooltipComponent status={subscription.status} />}</span>
          </p>
          <ItemsList />
        </div>
        <div className={`${styles.lftSection__bottom}`}>
          <div>
            <p>{translateMessage("Billing Period", lang)}</p>
            <p>{`${translateMessage("Every", lang)} ${plan?.planCycle} ${FullForm[plan?.planCyclePeriod!]}`}</p>
          </div>
          <div>
            <p>{translateMessage("Total Paid Cycles", lang)}</p>
            <p>{subscription.totalPaidCycles}/{subscription.remainingCycles}</p>
          </div>
        </div>
      </div>
      <hr className='d-md-none' />
      <div className={`${styles.rtSection}`}>

        <div>
          {subscription.prepaidSubscriptionStarts && <div>
            <p>{translateMessage("Subscription Start", lang)}</p>
            <p><ReadableDate isoDate={subscription.prepaidSubscriptionStarts} /></p>
          </div>}
          {subscription.prepaidSubscriptionEnds && <div>
            <p>{translateMessage("Subscription End", lang)}</p>
            <p><ReadableDate isoDate={subscription.prepaidSubscriptionEnds} /></p>
          </div>}
          {activeMandate && <div className={`${styles.activeMadate}`}>
            <div>
              <p className={styles.secText}>{translateMessage("Expires In", lang)}</p>
              <p>{timeInMs(activeMandate.expiresOn) <= 0 ? "Expired" : <Timer ms={timeInMs(activeMandate.expiresOn)} />}</p>
            </div>
            <div>
              <p className={styles.secText}>{translateMessage("Period From", lang)}</p>
              <p><ReadableDate isoDate={activeMandate.forPeriodFrom} /></p>
            </div>
            <div>
              <p className={styles.secText}>{translateMessage("Period To", lang)}</p>
              <p><ReadableDate isoDate={activeMandate.forPeriodTo} /></p>
            </div>
          </div>}
          {subscription.status === 'created' && <Alert style={{ opacity: 0.5 }} variant='warning'>{translateMessage("A new subscription is created", lang)}</Alert>}
          {subscription.status === 'pending' && <Alert style={{ opacity: 0.5 }} variant='warning'>{translateMessage("The subscription is now awaiting a payment & has a pending mandate attached to the subscription", lang)}</Alert>}
          {subscription.status === 'active' && <Alert style={{ opacity: 0.5 }} variant='success'>{translateMessage("No active Mandate", lang)}</Alert>}
          {subscription.status === 'halted' && <Alert style={{ opacity: 0.5 }} variant='danger'>{translateMessage("The subscription, which was initially in pending state, has its mandate expired causing to forceful termination of the subscription", lang)}</Alert>}
          {subscription.status === 'completed' && <Alert style={{ opacity: 0.5 }} variant='success'>{translateMessage("the subscription has completed all its billing cycles and is now terminated", lang)}</Alert>}
          {subscription.status === 'paused' && <Alert style={{ opacity: 0.5 }} variant='info'>{translateMessage("The subscription has been paused. Only subscriptions in active state can be paused", lang)}</Alert>}
          {subscription.status === 'cancelled' && <Alert style={{ opacity: 0.5 }} variant='light'>{translateMessage("The subscription has been cancelled and can no longer be continued", lang)}</Alert>}
        </div>


        <div className={`${styles.buttonSection}`}>
          <SubButton disabled={!activeMandate || timeInMs(activeMandate.expiresOn) <= 0} />
        </div>
      </div>
    </div>
  )
}

export default MainSection