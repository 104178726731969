import React from 'react';
import styles from './main.module.scss';
import StoreLogo from '../../assets/images/storeLogo.png';
import { translateMessage } from '../../helper/constant';
import { useLocalStorage } from '../../hook/useLocalStorage';

const ContainerHeader = () => {
  const [lang] = useLocalStorage('lang', 'en');
  return (
    <div className={`${styles.containerHeader}`}>
      <div className={`${styles.textSection}`}>
        <h3></h3>
        <h1 className='d-none d-md-block'>{translateMessage("Subscription Widget", lang)}</h1>
      </div>
      <div className={`d-none ${styles.logoSection}`}>
        {/* <img src={StoreLogo} alt='Store logo' className={styles.storeLogo}/> */}
      </div>
      <div className={`d-block d-md-none ${styles.textSection}`}>
        <h1>{translateMessage("Subscription Widget", lang)}</h1>
      </div>
    </div>
  )
}

export default ContainerHeader;
