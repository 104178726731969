import { LOADING, SET_SUBSCRIPTION_USER, SET_SUBSCRIPTION_PLAN, ERROR, SUCCESS, API_LOADING } from '../actions/actionTypes';
import { SubscriptionAction } from '../actions/subscriptonActions';

const initialState: SubscriptionState = {
  loading: false,
  apiLoading: false,
  error: null,
  success: null,
};

const subscriptionReducer = (state = initialState, action: SubscriptionAction): SubscriptionState => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.loading };
    case API_LOADING:
      return { ...state, apiLoading: action.loading };
    case SET_SUBSCRIPTION_USER:
      return { ...state, subscriptionUser: action.payload, error: undefined };
    case SET_SUBSCRIPTION_PLAN:
      return { ...state, subscriptionPlan: action.payload, error: undefined };
    case ERROR:
      return { ...state, error: action.error };
    case SUCCESS:
      return { ...state, success: action.message };
    default:
      return state;
  }
};

export default subscriptionReducer;
