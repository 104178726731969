import styles from "./container.module.scss"
import TopBar from "../TopBar";
import Main from "../Main";

const Container = () => {

	return (
		<div className={`${styles.mainContainer}`}>
			<TopBar/>
			<Main/>
		</div>
	);
}

export default Container;
