import React from 'react';

const ReadableDate = ({ isoDate }: any) => {
  const date = new Date(isoDate);

  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <div>
      {formattedDate} {formattedTime}
    </div>
  );
};

export default ReadableDate;
