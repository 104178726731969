import { useHistory } from 'react-router';
import React, { useState, useEffect, ChangeEvent } from 'react';

import styles from './topbar.module.scss';
import { ReactComponent as SunIcon } from '../../assets/svg/ph_sun.svg';
import { ReactComponent as MoonIcon } from '../../assets/svg/moon.svg';

const TopBar: React.FC = () => {
  const [language, setLanguage] = useState<string>(localStorage.getItem('lang') || 'en');
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      setIsDarkMode(true);
      document.documentElement.setAttribute('data-theme', 'dark');
      localStorage.setItem('theme', 'dark');
    } else {
      setIsDarkMode(false);
      document.documentElement.setAttribute('data-theme', 'light');
      localStorage.setItem('theme', 'light');
    }
  }, []);

  const handleLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value);
    localStorage.setItem('lang', e.target.value);
    history.go(0);
  };

  const handleThemeToggle = () => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      document.documentElement.setAttribute('data-theme', newMode ? 'dark' : 'light');
      localStorage.setItem('theme', newMode ? 'dark' : 'light');
      return newMode;
    });
  };

  return (
    <div className={styles.settings}>
      <div className={styles.languageSelectWrapper}>
        <select value={language} onChange={handleLanguageChange} className={styles.languageSelect}>
          <option value="en">EN</option>
          <option value="es">ES</option>
          <option value="ar">AR</option>
          <option value="de">DE</option>
          <option value="fr">FR</option>
          <option value="it">IT</option>
          <option value="ja">JA</option>
          <option value="ko">KO</option>
          <option value="pt">PT</option>
          <option value="tr">TR</option>
          <option value="zh-cn">ZH-CN</option>
        </select>
      </div>
      <label className={styles.themeToggle}>
        <input
          type="checkbox"
          checked={isDarkMode}
          onChange={handleThemeToggle}
          className={styles.themeSwitch}
        />
        <span className={styles.slider}>
          {isDarkMode ? <MoonIcon className={styles.icon} /> : <SunIcon className={styles.icon} />}
        </span>
      </label>
    </div>
  );
};

export default TopBar;
