import React from 'react';
import ContainerHeader from './Header';
import ContainerFooter from './Footer';
import styles from './main.module.scss';
import MainSection from './MainSection';

const Main: React.FC = () => {
  return (
    <div className={styles.mainContainer}>
      <ContainerHeader/>
      <MainSection/>
      <ContainerFooter/>
    </div>
  );
};

export default Main;
