import { useSelector } from 'react-redux';
import { translateMessage } from '../../helper/constant';
import { useLocalStorage } from '../../hook/useLocalStorage';
import { SpinnerCenter } from '../common/SpinnerCenter';
import { MouseEventHandler } from 'react';

interface ButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  currentStep: number;
  length: number;
}

const SubButton = ({ onClick, currentStep, length }: ButtonProps) => {
  const [lang] = useLocalStorage('lang', 'en');
  const { subscriptionReducer } = useSelector((state: RootState) => state);

  return (
    <button onClick={onClick}>
      {(currentStep === 1 && length > 0) ? translateMessage("Continue", lang) : translateMessage("Subscribe", lang)}
      {" "}
      {subscriptionReducer.apiLoading ? <SpinnerCenter /> : <SpinnerCenter className='d-none' />}
    </button>
  )
}

export default SubButton;
