import React, { useState, useRef } from 'react';
import styles from './main.module.scss';
import { ReactComponent as InfoSVG } from '../../assets/svg/info.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SubButton from './SubButton';
import { useSelector } from 'react-redux';
import { convertToSnakeCase, FullForm, GetCurrencySymbol, translateMessage } from '../../helper/constant';
import { useLocalStorage } from '../../hook/useLocalStorage';
import { subscribeUser } from '../../store/actions/subscriptonActions';
import { useHistory, useParams } from 'react-router';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../..';
import DynamicInput from '../DynamicInput';

const TooltipComponent = () => {
  const [lang] = useLocalStorage('lang', 'en');

  const renderTooltip = (props: any) => (
    <Tooltip id="info-tooltip" {...props}>
      {translateMessage("The subscription invoices will be sent on this email", lang)}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <InfoSVG />
    </OverlayTrigger>
  );
};

const ItemsList = () => {
  const { subscriptionPlan } = useSelector((state: RootState) => state.subscriptionReducer)

  return (
    <div className={`${styles.itemsList}`}>
      <div>
        <b>{subscriptionPlan?.planName}</b>
        <p>{subscriptionPlan?.planDescription}</p>
      </div>
      <div>
        <b className='text-nowrap'>{`${GetCurrencySymbol(subscriptionPlan?.planCurrency!)} ${subscriptionPlan?.planCost}`}</b>
      </div>
    </div>
  )
};

const MainSection = () => {
  const [email, setEmail] = useState('');
  const [lang] = useLocalStorage('lang', 'en');
  const [startDate, setStartDate] = useState(new Date());
  const [planStartOption, setPlanStartOption] = useState('immediate');
  const { subscriptionPlan } = useSelector((state: RootState) => state.subscriptionReducer);
  const [fields, setFields] = useState(subscriptionPlan?.customerData || []);
  const [currentStep, setCurrentStep] = useState(1);

  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const emailRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef<HTMLInputElement>(null);

  const handleContinue = () => {
    if (currentStep === 1) {
      if (!email) {
        emailRef.current?.focus();
        return;
      }
      if (planStartOption === 'selectDate' && !startDate) {
        dateRef.current?.focus();
        return;
      }
      if (fields.length > 0) {
        setCurrentStep(2);
      } else {
        handleSubmit();
      }
    } else {
      const unfilledField = fields.find((field, index) => {
        if (field.mandatory && !field.value) {
          return true;
        }
        return false;
      });
      if (unfilledField) {
        const firstMandatoryField: any = document.querySelector(
          `input[name="${convertToSnakeCase(unfilledField.label)}"]`
        );
        if (firstMandatoryField) {
          firstMandatoryField.focus();
        }
      }
      if (!unfilledField) {
        handleSubmit();
      }
    }
  };

  const handleSubmit = async () => {
    const startsAt = planStartOption === 'immediate' ? undefined : startDate.toISOString();
    await dispatch(subscribeUser(id, email, startsAt, (id?: any) => history.push(`/subscription/${id}`)));
  };

  const handleInputChange = (
    fieldKey: string,
    value: any,
    inputType?: string
  ) => {
    if (inputType === "qty-selector") {
      let updated: any = fields.map((field: any) => field.label === fieldKey ? { ...field, quantity: value } : field
      );
      setFields(updated)
    } else {
      let updated: any = fields.map((field: any) =>
        field.label === fieldKey ? { ...field, value } : field
      )
      setFields(updated);
    }
  };

  return (
    <div className={`${styles.mainSection}`}>
      <hr className='d-md-none' />
      <div className={`${styles.lftSection}`}>
        <div className={`${styles.lftSection__top}`}>
          <p>{translateMessage("Subscription Plan", lang)}</p>
          <ItemsList />
        </div>
        <div className={`${styles.lftSection__bottom}`}>
          <div>
            <p>{translateMessage("Billing Period", lang)}</p>
            <p>{`Every ${subscriptionPlan?.planCycle} ${FullForm[subscriptionPlan?.planCyclePeriod!]}`}</p>
          </div>
          {(subscriptionPlan?.cycleCount && subscriptionPlan?.cycleCount != 0) ? <div>
            <p>{translateMessage("Total Billing Cycles", lang)}</p>
            <p>{subscriptionPlan?.cycleCount}</p>
          </div> : <div>
            <p>{translateMessage("Total Billing Cycles", lang)}</p>
            <p className='text-secondary'>Until cancelled</p>
          </div>}
        </div>
      </div>
      <hr className='d-md-none' />
      <div className={styles.rtSection}>
        {currentStep === 1 ? (
          <div className={styles.emailSection}>
            <p>{translateMessage("Customer Email", lang)} <TooltipComponent /></p>
            <input
              id='email'
              placeholder={translateMessage('Email', lang)}
              required={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              ref={emailRef}
            />
            <div className={styles.planStartSection}>
              <p>{translateMessage("Plan Start", lang)}</p>
              <div className={styles.radioButtons}>
                <label>
                  <input
                    type="radio"
                    value="immediate"
                    checked={planStartOption === 'immediate'}
                    onChange={() => setPlanStartOption('immediate')}
                  />
                  {translateMessage("Immediate", lang)}
                </label>
                <label>
                  <input
                    type="radio"
                    value="selectDate"
                    checked={planStartOption === 'selectDate'}
                    onChange={() => setPlanStartOption('selectDate')}
                  />
                  {translateMessage("Select Date", lang)}
                </label>
              </div>
              {planStartOption === 'selectDate' && (
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date!)}
                  className={styles["react-datepicker-wrapper"]}
                  required={true}
                />
              )}
            </div>
          </div>
        ) : (
          <div className={styles.dynamicForm}>
            {fields && fields.length > 0 &&<p>{translateMessage("Customer Information", lang)} <TooltipComponent /></p>}
            {fields && fields.length > 0 && fields.map((item, index) => (
              <>
                <DynamicInput
                  key={item.label}
                  input={item}
                  onChange={handleInputChange}
                />
              </>
            ))}
          </div>
        )}
        <div className={styles.buttonSection}>
          <SubButton onClick={handleContinue} currentStep={currentStep} length={fields.length} />
        </div>
      </div>
    </div>
  )
}

export default MainSection;
