import { AppDispatch } from '..';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer } from "react-toastify";
import { Switch, Route, Redirect } from 'react-router-dom';

import Plan from '../components/Plan';
import Mandate from '../components/Mandate';
import Container from '../components/Container';
import { Error, Message } from '../store/actions/subscriptonActions';

import '../scss/main.scss';
import 'react-toastify/dist/ReactToastify.css';

export const App: React.FC = () => {
  const { error, success } = useSelector((state: RootState) => state.subscriptionReducer);
  const dispatch: AppDispatch  = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      setTimeout(() => {
        dispatch(Error(null));
      }, 2000)
    }
  }, [error]);
  
  useEffect(() => {
    if (success) {
      toast.success(success);
      setTimeout(() => {
        dispatch(Message(null));
      }, 2000)
    }
  }, [success]);

  return (
    <div>
      <ToastContainer />
      <Switch>
        <Route exact path="/" component={Container} />
        <Route exact path="/plan/:id" component={Plan} />
        <Route exact path="/subscription/:id" component={Mandate} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
};
