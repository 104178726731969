import React from 'react';
import styles from './main.module.scss';
import FooterLogo from "../../assets/images/footerLogo.png";
import { translateMessage } from '../../helper/constant';
import { useLocalStorage } from '../../hook/useLocalStorage';

const ContainerFooter = () => {
  const [lang] = useLocalStorage('lang', 'en');

  return (
    <div className={styles.contFooter}>
      <div className={styles.textFooter}>
        {translateMessage("Subscription payments are powered by GlobiancePay", lang)} <br />
        {translateMessage("To start accepting payments online visit", lang)} <span><a href={process.env.REACT_APP_GLOB_PAY_URL} target='_blank'>globiancepay.com</a></span>
      </div>
      <div className={styles.logoFooter}>
        <img src={FooterLogo} alt='Globiance Pay' className={styles.storeLogo}/>
      </div>
    </div>
  )
}

export default ContainerFooter;