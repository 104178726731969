import React from 'react'
import { useSelector } from 'react-redux';
import { ReactComponent as Eth } from '../../assets/svg/eth.svg';
import { ReactComponent as Plus } from '../../assets/svg/add.svg';
import { ReactComponent as Bitcoin } from '../../assets/svg/bitcoin.svg';
import { translateMessage } from '../../helper/constant';
import { useLocalStorage } from '../../hook/useLocalStorage';

interface SubButton {
  disabled: boolean;
}

declare global {
  interface Window {
    Start: (payload: any) => void;
  }
}

const SubButton: React.FC<SubButton> = ({ disabled }) => {
  const [lang] = useLocalStorage('lang', 'en');
  const { subscriptionUser } = useSelector((state: RootState) => state.subscriptionReducer);
  const { plan, activeMandate } = subscriptionUser!;

  const startWidget = () => {
    const payload = {
      apikey: plan.apiKey,
      currency: plan.planCurrency,
      language: 'en',
      theme: localStorage.getItem('theme') || 'light',
      origin: "subscription",
      originId: activeMandate?.mandateId,
      items: [
        {
          itemName: plan.planName,
          price: plan.planCost,
          qty: 1,
          taxRate: plan.taxRate
        }
      ]
    }

    if(window.Start) window.Start(payload);
  }

  return (
    <button onClick={()=>startWidget()} disabled={disabled}>
      {translateMessage("Pay Via Crypto", lang)} <span>
        <Bitcoin />
        <Eth />
        <Plus />
      </span>
    </button>
  )
}

export default SubButton;